import { Box } from '@chakra-ui/core'
import dynamic from 'next/dynamic'
import React from 'react'

import { useRTL } from '../../../i18n/hooks'

const LightFooter = dynamic(() => import('../Footer/LightFooter'))
const TopNav = dynamic(() => import('../LightTopNav'))

export type LightLayoutProps = {
  hasFooter?: boolean
  hasTopNav?: boolean
  bgColor?: string
  enableRTL?: boolean
}

const LightLayout: React.FC<LightLayoutProps> = ({
  children,
  hasTopNav = true,
  hasFooter,
  bgColor,
  enableRTL = false,
}) => {
  const { dir } = useRTL()

  return (
    <>
      <Box
        minHeight="100vh"
        bgColor={bgColor || 'offwhite'}
        dir={enableRTL ? dir : 'ltr'}
      >
        {hasTopNav ? <TopNav /> : null}
        {children}
        {hasFooter ? <LightFooter /> : null}
      </Box>
    </>
  )
}

export default LightLayout
